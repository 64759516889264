<script lang="ts" setup>
import AppMenu from './AppMenu.vue';
import AppTopbar from './AppTopbar.vue';

const { layoutState, isHorizontal } = useLayout();

let timeout = null;

function onMouseEnter() {
  if (!layoutState.value.anchored) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    layoutState.value.valuesidebarActive = true;
  }
}

function onMouseLeave() {
  if (!layoutState.value.anchored) {
    if (!timeout) {
      timeout = setTimeout(() => (layoutState.value.sidebarActive = false), 300);
    }
  }
}

function onAnchorToggle() {
  layoutState.value.anchored = !layoutState.value.anchored;
}
</script>

<template>
  <div class="layout-sidebar" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="sidebar-header">
      <router-link :to="{ name: 'index' }" class="logo">
        <img class="logo-image" src="/img/rhinocalls-192x192.png" alt="RhinoCalls" />
        <span class="app-name text-4xl font-medium leading-normal">RhinoCalls</span>
      </router-link>
      <button class="layout-sidebar-anchor" type="button" @click="onAnchorToggle" />
    </div>
    <div class="layout-menu-container">
      <AppMenu />
    </div>
    <AppTopbar v-if="isHorizontal" />
  </div>
</template>
