<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';
import AppBreadcrumb from '~/components/layout/AppBreadcrumb.vue';
import AppConfig from '~/components/layout/AppConfig.vue';
import AppFooter from '~/components/layout/AppFooter.vue';
import AppRightMenu from '~/components/layout/AppRightMenu.vue';
import AppSidebar from '~/components/layout/AppSidebar.vue';
import AppTopbar from '~/components/layout/AppTopbar.vue';
import * as Sentry from "@sentry/vue";
import Dialer from "~/components/dialer/Dialer.vue";

const { account } = useAuth()
const { currentCompany } = useCompany()
const { layoutConfig, layoutState, isSidebarActive } = useLayout()
const outsideClickListener = ref<((event: any) => void)>();
const url = useRequestURL()

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const containerClass = computed(() => {
  return [
    {
      'layout-overlay': layoutConfig.value.menuMode === 'overlay',
      'layout-static': layoutConfig.value.menuMode === 'static',
      'layout-slim': layoutConfig.value.menuMode === 'slim',
      'layout-horizontal': layoutConfig.value.menuMode === 'horizontal',
      'layout-compact': layoutConfig.value.menuMode === 'compact',
      'layout-reveal': layoutConfig.value.menuMode === 'reveal',
      'layout-drawer': layoutConfig.value.menuMode === 'drawer',
      'layout-overlay-active': layoutState.value.overlayMenuActive || layoutState.value.staticMenuMobileActive,
      'layout-mobile-active': layoutState.value.staticMenuMobileActive,
      'layout-static-inactive': layoutState.value.staticMenuDesktopInactive && layoutConfig.value.menuMode === 'static',
      'layout-sidebar-active': layoutState.value.sidebarActive,
      'layout-sidebar-anchored': layoutState.value.anchored
    }
  ];
});

function bindOutsideClickListener() {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.value.overlayMenuActive = false;
        layoutState.value.overlaySubmenuActive = false;
        layoutState.value.staticMenuMobileActive = false;
        layoutState.value.menuHoverActive = false;
        layoutState.value.configSidebarVisible = false;
      }
    };

    setTimeout(() => {
      document.addEventListener('click', outsideClickListener.value);
    }, 0);
  }
}

function unbindOutsideClickListener() {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value);
    outsideClickListener.value = undefined;
  }
}

function isOutsideClicked(event) {
  const sidebarEl = document.querySelector('.layout-sidebar');
  const topbarButtonEl = document.querySelector('.topbar-left > a');

  return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarButtonEl?.isSameNode(event.target) || topbarButtonEl?.contains(event.target));
}

const siteName = computed<string>(() => {
  if (currentCompany.value) return currentCompany.value.name;

  if (url.hostname === 'crmig.org') return "CRMIG";
  return "RhinoCalls";
});

const setupSentry = () => {
  if (!account.value) return;

  Sentry.setUser({
    id: account.value.id,
    email: account.value.email,
  });
}

onMounted(async () => {
  setTimeout(function () {
    setupSentry();
  }, 5000);
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value;
  },
});
</script>

<template>
  <div class="layout-wrapper" :class="containerClass">
    <img class="absolute w-[144px] h-auto top-0 left-0 mix-blend-color-dodge pointer-events-none hidden dark:block" src="/images/app-light-1.png" alt="App Light" />
    <img class="absolute w-[766px] h-auto top-0 right-0 mix-blend-color-dodge pointer-events-none hidden dark:block" src="/images/app-light-2.png" alt="App Light" />
    <img class="absolute w-[327px] h-auto bottom-0 left-52 mix-blend-color-dodge pointer-events-none hidden dark:block" src="/images/app-light-3.png" alt="App Light" />
    <AppSidebar ref="sidebarRef" />
    <div class="layout-content-wrapper">
      <div class="layout-content-wrapper-inside">
        <AppTopbar />
        <div class="layout-content">
          <AppBreadcrumb />
          <NuxtPage />
          <Dialer />
        </div>
        <AppFooter />
      </div>
    </div>
    <AppConfig />
    <!--<AppSearch />-->
    <AppRightMenu v-if="account && currentCompany" />
    <ConfirmDialog />
    <DynamicDialog />
    <Toast />
    <div class="layout-mask" />
  </div>
</template>
