<script lang="ts" setup>
import type Menu from 'primevue/menu'
import type Popover from 'primevue/popover'
import NotificationCard from '~/components/cards/NotificationCard.vue'

const { currentCompany } = useCompany()
const read = ref<boolean>()

const pagination = ref<PaginationInput>({
  page: 1,
  per_page: 5,
})

const fetchQuery = computed(() => ({ ...pagination.value, read: read.value }))

const { data: objectList, refresh, status } = useApi<Paginated<NotificationInstance>>('/notifications/', {
  query: fetchQuery,
  watch: [currentCompany, pagination, read]
})

const options = [
  {label: 'All', value: undefined},
  {label: 'Unread', value: false},
  {label: 'Read', value: true},
]

const markReadAll = async () => {
  const { $api } = useNuxtApp()
  await $api(`/notifications/mark-read/`, {
    method: 'POST'
  })
    .then(() => refresh())
}
</script>

<template>
  <li class="right-sidebar-item static sm:relative">
    <a
      v-styleclass="{
        selector: '@next',
        enterActiveClass: 'animate-scalein',
        enterFromClass: 'hidden',
        leaveActiveClass: 'animate-fadeout',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true
      }"
      class="right-sidebar-item relative z-50"
    >
      <span class="absolute bg-red-500 h-2 right-2.5 rounded-full top-2 w-2" />
      <i class="pi pi-bell" />
    </a>
    <div
      class="
        absolute
        border border-surface
        bg-surface-0
        hidden
        list-none
        m-0
        min-w-72
        mt-2
        origin-top
        overflow-hidden
        px-4
        py-4
        right-0
        rounded-3xl
        shadow-[0px_56px_16px_0px_rgba(0,0,0,0.00),0px_36px_14px_0px_rgba(0,0,0,0.01),0px_20px_12px_0px_rgba(0,0,0,0.02),0px_9px_9px_0px_rgba(0,0,0,0.03),0px_2px_5px_0px_rgba(0,0,0,0.04)]
        top-auto
        z-50
        dark:bg-surface-900
        sm:w-[24rem]
      "
    >
      <div class="flex gap-2 items-center justify-between">
        <span class="font-medium text-lg text-surface-950 dark:text-surface-0">Notifications</span>
        <button class="font-medium text-sm text-surface-700 dark:text-surface-300" @click="markReadAll">Mark all as read</button>
      </div>

      <div
        class="
          bg-white/55
          border
          border-surface-200
          flex
          gap-2
          items-center
          my-2
          p-1
          rounded-full
          shadow-custom-shadow
          dark:bg-white/8
          dark:border-surface-800
        "
      >
        <button
          v-for="item in options"
          :key="item.label"
          class="flex flex-1 items-center justify-center p-2 rounded-full transition-all"
          :class="read === item.value ? 'bg-primary-100 dark:bg-primary-900' : 'cursor-pointer dark:hover:bg-primary-900/30 hover:bg-primary-100/30'"
          @click="read = item.value"
        >
          <span class="capitalize font-medium text-surface-950 dark:text-surface-0">{{ item.label }}</span>
        </button>
      </div>
      <ul class="flex flex-col gap-8">
        <li
            v-for="notification in objectList?.results"
            :key="notification.id"
            :class="{
              'cursor-pointer hover:bg-surface-100 hover:dark:bg-surface-800': notification.link
            }"
            class="flex gap-3"
            @click="notification.link ? navigateTo(notification.link) : null"
          >
            <div class="flex flex-col items-center gap-1">
              <span class="bg-surface-200 flex-1 w-px dark:bg-surface-800" />
            </div>

            <div class="flex-1 pt-2 space-y-2">
              <div class="flex gap-2 items-center justify-between">
                <span class="font-medium text-surface-950 dark:text-surface-0">{{ notification.title }}</span>
                <div class="flex gap-1 items-center text-sm text-surface-700 dark:text-surface-200 ">
                  {{ formatDateAgo(notification.date_creation) }}
                  <div v-if="notification.date_read" class="bg-green-500 !h-2 rounded-full !w-2" />
                </div>
              </div>
              <div v-if="notification.content" class="bg-surface-100 border border-surface p-2 rounded-lg dark:bg-surface-800">
                <p class="line-clamp-2 text-ellipsis text-sm text-surface-700 dark:text-surface-200">
                  {{ notification.content }}
                </p>
              </div>
            </div>
          </li>

      </ul>
    </div>
  </li>
</template>
