<script lang="ts" setup>
import AppBreadcrumb from "./AppBreadcrumb.vue";
import NotificationList from "~/components/lists/NotificationList.vue";

const { layoutState, toggleMenu, toggleConfigSidebar } = useLayout();

const { account, logout } = useAuth()
const { currentCompany } = useCompany()

function showRightMenu() {
  layoutState.value.rightMenuVisible = !layoutState.value.rightMenuVisible;
}
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-left">
      <a tabindex="0" class="menu-button" @click="toggleMenu">
        <i class="pi pi-chevron-left" />
      </a>
      <img class="horizontal-logo" src="/img/rhinocalls-192x192.png" alt="RhinoCalls" />
      <span class="topbar-separator" />
      <AppBreadcrumb />
      <NuxtLink :to="{ name: 'index' }">
        <img class="mobile-logo" src="/img/rhinocalls-192x192.png" alt="RhinoCalls" />
      </NuxtLink>
    </div>

    <div class="topbar-right">
      <ul class="topbar-menu">
        <li class="right-sidebar-item">
          <button type="button" class="app-config-button cursor-pointer" @click="toggleConfigSidebar" aria-label="Toggle Config Sidebar">
            <i class="pi pi-cog" />
          </button>
        </li>
        <NotificationList v-if="account && currentCompany" />

        <li v-if="account" class="profile-item static sm:relative">
          <a
            class="!bg-none !border-none !outline-none"
            v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveActiveClass: 'animate-fadeout', leaveToClass: 'hidden', hideOnOutsideClick: true }"
          >
            <Avatar
              :label="account?.first_name ? account.first_name[0] : 'N'"
              class="!bg-primary !h-10 !w-10"
            />
          </a>
          <div
              class="list-none p-2 m-0 rounded-2xl border border-surface overflow-hidden absolute bg-surface-0 dark:bg-surface-900 hidden origin-top w-52 mt-2 right-0 z-[999] top-auto shadow-[0px_56px_16px_0px_rgba(0,0,0,0.00),0px_36px_14px_0px_rgba(0,0,0,0.01),0px_20px_12px_0px_rgba(0,0,0,0.02),0px_9px_9px_0px_rgba(0,0,0,0.03),0px_2px_5px_0px_rgba(0,0,0,0.04)]"
          >
            <ul class="flex flex-col gap-1">
              <li>
                <NuxtLink
                  :to="{ name: 'account' }"
                  class="label-small dark:text-surface-400 flex gap-2 py-2 px-2.5 rounded-lg items-center hover:bg-emphasis transition-colors duration-150 cursor-pointer"
                >
                  <i class="pi pi-user" />
                  <span>Profile</span>
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{ name: 'account-password' }"
                  class="label-small dark:text-surface-400 flex gap-2 py-2 px-2.5 rounded-lg items-center hover:bg-emphasis transition-colors duration-150 cursor-pointer"
                >
                  <i class="pi pi-key" />
                  <span>Change password</span>
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{ name: 'index' }"
                  class="label-small dark:text-surface-400 flex gap-2 py-2 px-2.5 rounded-lg items-center hover:bg-emphasis transition-colors duration-150 cursor-pointer"
                >
                  <i class="pi pi-home" />
                  <span>Switch company</span>
                </NuxtLink>
              </li>
              <li>
                <a
                  class="label-small dark:text-surface-400 flex gap-2 py-2 px-2.5 rounded-lg items-center hover:bg-emphasis transition-colors duration-150 cursor-pointer"
                  @click="() => logout()"
                >
                  <i class="pi pi-power-off" />
                  <span>Log out</span>
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="account && currentCompany" class="right-sidebar-item">
          <a tabindex="0" class="right-sidebar-button" @click="showRightMenu">
            <i class="pi pi-align-right" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
